<template>
  <div class="container-fluid text-left">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <h4>Sleep Sessions - {{ summ.patientName }}</h4>
      </div>
      <div class="card-body">
        <div class="row mt-2 text-center">
          <div class="col-4 border-right">
            <button class="btn nw-btn-outline-info-sm" @click="moveDate(-30)">-30 Days</button>
            <button class="btn nw-btn-outline-info-sm ml-2" @click="moveDate(0)">Today</button>
            <button class="btn nw-btn-outline-info-sm ml-2" @click="moveDate(30)">+30 Days</button>
          </div>
          <div class="col-4 border-right">
            <button class="btn" :class="numDays==30 ? ' nw-btn-info-sm'  :'nw-btn-outline-info-sm'" @click="setNumDays(30)">30 Days</button>
            <button class="btn ml-2" :class="numDays==60 ? ' nw-btn-info-sm'  :'nw-btn-outline-info-sm'" @click="setNumDays(60)">60 Days</button>
            <button class="btn ml-2" :class="numDays==90 ? ' nw-btn-info-sm'  :'nw-btn-outline-info-sm'" @click="setNumDays(90)">90 Days</button>
          </div>
          <div class="col-4">
            <a class="btn nw-btn-outline-info-sm" title="Download PDF" target="_blank" :href="pdfURL"><i class="fa fa-file-pdf-o"></i></a>
            <a class="btn nw-btn-outline-info-sm ml-2" title="Download Summary CSV" target="_blank" :href="csvURL"><i class="fa fa-file-excel-o"></i></a>
            <a class="btn nw-btn-outline-info-sm ml-2" title="Download Stats CSV" target="_blank" :href="statsURL"><i class="fa fa-file-excel-o"></i></a>
          </div>
        </div>
        <div class="row border-top mt-1">
          <div class="col-3 text-right">First Session Date:</div>
          <div class="col-3 font-weight-bold">{{ startDate.toLocaleDateString() }}</div>
          <div class="col-3 text-right">Min # Interventions:</div>
          <div class="col-3 font-weight-bold">{{ summ.minInt}}</div>
        </div>
        <div class="row mt-1">
          <div class="col-3 text-right">Last Session Date:</div>
          <div class="col-3 font-weight-bold">{{ endDate.toLocaleDateString() }}</div>
          <div class="col-3 text-right">Max # Interventions:</div>
          <div class="col-3 font-weight-bold">{{ summ.maxInt}}</div>
        </div>
        <div class="row mt-1">
          <div class="col-3 text-right">Utilization:</div>
          <div class="col-3 font-weight-bold">{{ summ.utilization.toFixed(2)}}%</div>
          <div class="col-3 text-right">Avg # Interventions:</div>
          <div class="col-3 font-weight-bold">{{ summ.avgInt}}</div>
        </div>
        <table class="table table-striped table-sm">

          <tbody>
            <tr class="text-center">
              <td class="border-left"></td>
              <td colspan="12" class="border-left">PM</td>
              <td colspan="12" class="border-left border-right">AM</td>
              <td colspan="3 text-center">Measures</td>
            </tr>
            <tr class="border-bottom">
              <td>Date</td>
              <td>12</td>
              <td></td>
              <td></td>
              <td>3</td>
              <td></td>
              <td></td>
              <td>6</td>
              <td></td>
              <td></td>
              <td>9</td>
              <td></td>
              <td></td>
              <td>12</td>
              <td></td>
              <td></td>
              <td>3</td>
              <td></td>
              <td></td>
              <td>6</td>
              <td></td>
              <td></td>
              <td>9</td>
              <td></td>
              <td></td>
              <td class="border-left text-center">Dur</td>
              <td class="border-left text-center">Int</td>
              <td class="border-left text-center">Thr</td>
            </tr>
            <tr class="border-bottom" v-for="item in summ.days">
              <td class="border-right">{{ item.day }}</td>
              <td :class="barClass(item.hour, 0)" @click="select(item.hour[0])"></td>
              <td :class="barClass(item.hour, 1)" @click="select(item.hour[1])"></td>
              <td :class="barClass(item.hour, 2)" @click="select(item.hour[2])"></td>
              <td :class="barClass(item.hour, 3)" @click="select(item.hour[3])"></td>
              <td :class="barClass(item.hour, 4)" @click="select(item.hour[4])"></td>
              <td :class="barClass(item.hour, 5)" @click="select(item.hour[5])"></td>
              <td :class="barClass(item.hour, 6)" @click="select(item.hour[6])"></td>
              <td :class="barClass(item.hour, 7)" @click="select(item.hour[7])"></td>
              <td :class="barClass(item.hour, 8)" @click="select(item.hour[8])"></td>
              <td :class="barClass(item.hour, 9)" @click="select(item.hour[9])"></td>
              <td :class="barClass(item.hour, 10)" @click="select(item.hour[10])"></td>
              <td :class="barClass(item.hour, 11)" @click="select(item.hour[11])"></td>
              <td class="border-left":class="barClass(item.hour, 12)" @click="select(item.hour[12])"></td>
              <td :class="barClass(item.hour, 13)" @click="select(item.hour[13])"></td>
              <td :class="barClass(item.hour, 14)" @click="select(item.hour[14])"></td>
              <td :class="barClass(item.hour, 15)" @click="select(item.hour[15])"></td>
              <td :class="barClass(item.hour, 16)" @click="select(item.hour[16])"></td>
              <td :class="barClass(item.hour, 17)" @click="select(item.hour[17])"></td>
              <td :class="barClass(item.hour, 18)" @click="select(item.hour[18])"></td>
              <td :class="barClass(item.hour, 19)" @click="select(item.hour[19])"></td>
              <td :class="barClass(item.hour, 20)" @click="select(item.hour[20])"></td>
              <td :class="barClass(item.hour, 21)" @click="select(item.hour[21])"></td>
              <td :class="barClass(item.hour, 22)" @click="select(item.hour[22])"></td>
              <td :class="barClass(item.hour, 23)" @click="select(item.hour[23])"></td>
              <td class="text-center border-left">{{ item.nodata == true ? '' : item.duration.toFixed(1)}}</td>
              <td class="text-center border-left">{{ item.nodata == true ? '---' : item.interventions}}</td>
              <td class="text-center border-left">{{ item.nodata == true ? '' : item.threshold}}</td>
            </tr>

          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'

export default {
  data () {
    return {
      nc: NetClient,
      numDays: 30,
      endDate: new Date(),
      startDate: new Date(((new Date()).getTime() - (30 * 86400000))),
      summ: {
    		patientName: "Name",
    		patientID: "777777.888",
    		patientEmail: "",
    		patientPhone: "",
	  		maxInt: 0,
    		minInt: 1000,
    		avgInt: 0,
    		utilization: 0,
		    days: []
      }
    }
  },
  computed: {
      csvURL : function() {
        var url = process.env.VUE_APP_HOST_NAME + 'rest/report?repID=ssexcel&uid=' + NetClient.ctx.uid + '&token=' + NetClient.ctx.token
        url += '&endDate=' + this.endDate.toLocaleDateString() + '&numDays=' + this.numDays

        return url
      },
      statsURL : function() {
        var url = process.env.VUE_APP_HOST_NAME + 'rest/report?repID=statsexcel&uid=' + NetClient.ctx.uid + '&token=' + NetClient.ctx.token
        url += '&endDate=' + this.endDate.toLocaleDateString() + '&numDays=' + this.numDays

        return url
      },
      pdfURL : function() {
        var url = process.env.VUE_APP_HOST_NAME + 'rest/report?repID=sspdf&uid=' + NetClient.ctx.uid + '&token=' + NetClient.ctx.token
        url += '&endDate=' + this.endDate.toLocaleDateString() + '&numDays=' + this.numDays

        return url
      }
  },
  mounted () {
    this.refreshSummary()
  },
  methods : {
    select (_oid) {
      if(_oid <= 0) return
      NetClient.sessionID = _oid
      this.$router.push({name: 'SessionDetail'})
    },
    barClass (_hour, _time)
    {
      if(typeof _hour[_time] == 'undefined') return 'bg-danger'
      return _hour[_time] > 0 ? 'bg-primary' : ''
    },
    setStartDate () {
      this.startDate.setTime(this.endDate.getTime() - (this.numDays * 86400000))
    },
    moveDate(_days) {
      if(_days == 0) this.endDate = new Date()
      else this.endDate = new Date(this.endDate.getTime() + (_days * 86400000))
      this.startDate = new Date(this.endDate.getTime() - (this.numDays * 86400000))
      this.refreshSummary()
    },
    refreshSummary () {
      NetClient.doRequest('rest/patient', { action: 'getSleepSessionSummary', endDate: this.endDate.toLocaleDateString(), numDays: this.numDays}).then(msg => {
        if(typeof msg.data != 'undefined') this.summ = msg.data
      })
    },
    setNumDays (_days) {
      this.numDays = _days
      this.setStartDate()
      this.refreshSummary()
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
